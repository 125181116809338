<template>
  <div v-if="loading && stateLoading">
    <Loader />
  </div>
  <div class="card" v-if="!loading && !stateLoading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        รายงานยอดขาย / ลูกค้า
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale me-3"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
          @mouseenter="hoverABtn"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>

        <div v-if="hover3">
          <DialogExportFile
            :dialogExportFile="dialogExportFile"
            @closeDialogExportFile="closeDialogExportFile"
            @selectedTypeExportFile="selectedTypeExportFile"
          />
        </div>

        <button
          type="button"
          class="btn btn-sm btn-light-primary me-3"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart1()"
          @click="openChart1()"
        >
          กราฟจำนวนลูกค้า
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart2()"
          @click="openChart2()"
        >
          กราฟยอดขาย
        </button>
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-range-picker
          v-model:value="searchInput"
          format="DD/MM/YYYY"
          style="border-radius: 6px; height: 38px"
          inputReadOnly
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table align-middle table-hover table-row-bordered table-row-dashed gy-5"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-4 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">เวลา</th>
              <th class="text-center">ยอดขาย</th>
              <th class="text-center">จำนวนบิล</th>
              <th class="text-center">ยอดขายเฉลี่ยต่อบิล</th>
              <th class="text-center">จำนวนสินค้า</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-if="reportDate.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr> -->
            <tr v-for="(item, index) in reportDate" :key="index">
              <td
                :style="
                  this.reportDate.length === index + 1
                    ? 'font-weight: 900; font-size: 20px'
                    : 'font-weight: bold'
                "
                class="text-center"
              >
                {{ item.time }}
              </td>
              <td
                :style="
                  this.reportDate.length === index + 1
                    ? 'font-weight: bold; font-size: 18px'
                    : ''
                "
                class="text-end"
              >
                {{ formatPrice(item.sales) }}
              </td>
              <td
                :style="
                  this.reportDate.length === index + 1
                    ? 'font-weight: bold; font-size: 18px'
                    : ''
                "
                class="text-end"
              >
                {{ item.customerAmt }}
              </td>
              <td
                :style="
                  this.reportDate.length === index + 1
                    ? 'font-weight: bold; font-size: 18px'
                    : ''
                "
                class="text-end"
              >
                {{ formatPrice(item.salesPerBill) }}
              </td>
              <td
                :style="
                  this.reportDate.length === index + 1
                    ? 'font-weight: bold; font-size: 18px'
                    : ''
                "
                class="text-end"
              >
                {{ item.productAmt }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="hover1">
      <Chart1
        :dialogChart1="dialogChart1"
        :dataChart1="dataChart1"
        @closeDialogChart1="closeDialogChart1"
      />
    </div>

    <div v-if="hover2">
      <Chart2
        :dialogChart2="dialogChart2"
        :dataChart2="dataChart2"
        @closeDialogChart2="closeDialogChart2"
      />
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import reportApi from "@/api/report/";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import { dataReport1 } from "@/core/report/report1/dataReport1";
import { filterTimeByHour } from "@/utils/reports/report1/filterReport1";

import Chart1 from "./components/chart1.vue";
import Chart2 from "./components/chart2.vue";
import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { mapState } from "vuex";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
    Chart1,
    Chart2,
  },
  setup() {
    document.title = "BELUCA | รายงานยอดขาย / ลูกค้า";
  },
  data: () => ({
    loading: false,
    dataItemsShow: [],

    showing1: null,

    tableItems: [],

    searchInput: [],
    dateFormat: "YYYY/MM/DD",

    dataChart1: [],
    dataChart2: [],

    dialogChart1: false,
    dialogChart2: false,

    hover1: false,
    hover2: false,
    hover3: false,

    //export
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    loadingExport1: false,
  }),

  watch: {
    dialogExportFile(val) {
      console.log(val, 123);
    },
  },

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  created() {
    this.reportDate = dataReport1();
    this.setDateToday();
    this.search();
  },

  methods: {
    setDateToday() {
      this.searchInput[0] = dayjs(new Date());
      this.searchInput[1] = dayjs(new Date());
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      if (this.searchInput) {
        this.hover3 = false;
        this.isExcel = false;
        this.isPdf = false;

        this.loading = true;
        this.$store.commit(Mutations.SET_LOADING, true);
        const companyId = localStorage.getItem("companyId");
        const companyBranchId = localStorage.getItem("companyBranchId");

        this.reportDate = dataReport1();

        const newDateStart = moment(this.searchInput[0].$d).format(
          "YYYY-MM-DD"
        );
        const newDateEnd = moment(this.searchInput[1].$d)
          .add(1, "days")
          .format("YYYY-MM-DD");

        const responseSearch = await reportApi.sale.searchByDate(
          {
            dateStart: newDateStart,
            dateEnd: newDateEnd,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
        );

        if (responseSearch.response_status === "SUCCESS") {
          this.handleFilterReport(responseSearch.data);
        }
      }
    },

    async handleFilterReport(item) {
      let newItemSales = [];
      let newItemPos = [];

      // set hour sales order
      item[0].forEach((element, index) => {
        newItemSales.push({
          ...element,
          newCreatedAt: moment(element.createdAt).format("H:mm"),
        });
      });

      // set hour POS
      item[1].forEach((element, index) => {
        newItemPos.push({
          ...element,
          newCreatedAt: moment(element.createdAt).format("H:mm"),
        });
      });

      const newData = await filterTimeByHour(
        newItemSales,
        this.reportDate,
        newItemPos
      );

      if (newData.length > 0) {
        this.reportDate = newData;
        this.dataChart1 = newData;
        this.dataChart2 = newData;
        this.setDateAvg(newData);
      }
      this.loading = false;
      setTimeout(() => {
        this.$store.commit(Mutations.SET_LOADING, false);
      }, 500);
    },

    setDateAvg(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDate.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.dataExportPdf = this.reportDate;
      this.dataExportExcel = this.reportDate;
    },

    // chart 1
    openChart1() {
      this.dialogChart1 = true;
    },

    closeDialogChart1() {
      this.dialogChart1 = false;
    },

    hoverChart1() {
      this.hover1 = true;
      this.hover2 = false;
      this.hover3 = false;
    },

    // chart 2
    openChart2() {
      this.dialogChart2 = true;
    },

    closeDialogChart2() {
      this.dialogChart2 = false;
    },

    hoverChart2() {
      this.hover1 = false;
      this.hover2 = true;
      this.hover3 = false;
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },

    hoverABtn() {
      this.hover1 = false;
      this.hover2 = false;
      this.hover3 = true;
    },
    //export file excel, pdf
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
